import styled from "styled-components"

type StyledButtonProps = { hasPrefix: boolean; disabled?: boolean; block?: boolean }

export const StyledButton = styled.button<StyledButtonProps>`
    ${({ block }) => block && "width: 100%;"}

    background: #ffffff;
    border: 1px solid ${({ disabled }) => (disabled ? "#D3D6D9" : "#191919")};
    color: ${({ disabled }) => (disabled ? "rgba(110, 109, 121, 0.5)" : "#191919")};
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    // line-height: 22px;
    padding: 9px ${({ block }) => (block ? 0 : "20px")};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    display: flex;
    justify-content: center;
    align-items: center;

    > * {
        &:first-child {
            ${(props) => props.hasPrefix && "margin-right: 7px !important;"}
        }
    }

    span,
    img {
        display: block;
    }

    &:active {
        outline: none;
        background: #f7f7f7;
    }
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: ${({ disabled }) =>
            disabled ? "none" : "0px 8px 20px -5px rgba(0, 0, 0, 0.12)"};
    }
`
