import {
    ModalActionType,
    ModalPropsType,
    RiddleAnswersActionType,
    SetActionActionType,
    SetEvidenceGroupActiveKeysActionType,
    SetHideHeaderActionType,
    SetStorylineActiveKeyActionType,
} from "./types"
import { createAction } from "typesafe-actions"

export const addModal = createAction(ModalActionType.ADD_MODAL)<ModalPropsType>()

export const removeModal = createAction(ModalActionType.REMOVE_MODAL)<string>()

export const removeAllModal = createAction(ModalActionType.REMOVE_ALL_MODAL)()

export const setRiddlesAnswers = createAction(RiddleAnswersActionType.SET_RIDDLE_ANSWERS)<{
    [key: number]: number | null
}>()

export const setHideHeader = createAction(SetHideHeaderActionType.SET_HIDE_HEADER)<{
    hideHeader: boolean
}>()

export const resetRiddlesAnswers = createAction(RiddleAnswersActionType.RESET_RIDDLE_ANSWERS)()

export const setStorylineActiveKey = createAction(
    SetStorylineActiveKeyActionType.SET_STORYLINE_ACTIVE_KEY
)<{ storylineActiveKey: number }>()

export const setEvidenceGroupActiveKeys = createAction(
    SetEvidenceGroupActiveKeysActionType.SET_EVIDENCE_ACTIVE_KEYS
)<{ evidenceGroupActiveKeys: { [key: number]: boolean } }>()

export const setAction = createAction(SetActionActionType.SET_ACTION)<{ action: () => void }>()

export const removeAction = createAction(SetActionActionType.REMOVE_ACTION)()
