import { ErrorMessage } from "formik"
import { StyledSubText } from "../../styles/Text/Text.styles"
import { StyledErrorMessage } from "./CustomErrorMessage.styles"

const CustomErrorMessage = (props: any) => {
    return (
        <StyledErrorMessage>
            <StyledSubText color="green">
                <ErrorMessage {...props} />
            </StyledSubText>
        </StyledErrorMessage>
    )
}

export default CustomErrorMessage
