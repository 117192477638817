export const SIGN_IN_MODAL = "SIGN_IN_MODAL"
export const SIGN_UP_MODAL = "SIGN_UP_MODAL"
export const FORGOT_PASSWORD_MODAL = "FORGOT_PASSWORD_MODAL"
export const FORGOT_PASSWORD_CONFIRMATION = "FORGOT_PASSWORD_CONFIRMATION"
export const SET_NEW_PASSWORD_MODAL = "SET_NEW_PASSWORD_MODAL"
export const EMAIL_SENT_MODAL = "EMAIL_SENT_MODAL"
export const ENTER_CODE_MODAL = "ENTER_CODE_MODAL"
export const RESENT_EMAIL_MODAL = "RESENT_EMAIL_MODAL"
export const SUCCESS_LOGIN_MODAL = "SUCCESS_LOGIN_MODAL"
export const SUCCESS_UNSUBSCRIBE_MODAL = "SUCCESS_UNSUBSCRIBE_MODAL"

export const RIDDLE_MODAL_QUESTION_ONE = "RIDDLE_MODAL_QUESTION_ONE"
export const RIDDLE_MODAL_ANSWER_ONE = "RIDDLE_MODAL_ANSWER_ONE"
export const RIDDLE_MODAL_QUESTION_TWO = "RIDDLE_MODAL_QUESTION_TWO"
export const RIDDLE_MODAL_ANSWER_TWO = "RIDDLE_MODAL_ANSWER_TWO"
export const RIDDLE_MODAL_QUESTION_THREE = "RIDDLE_MODAL_QUESTION_THREE"
export const RIDDLE_MODAL_ANSWER_THREE = "RIDDLE_MODAL_ANSWER_THREE"
export const RIDDLE_MODAL_QUESTION_FOUR = "RIDDLE_MODAL_QUESTION_FOUR"
export const RIDDLE_MODAL_ANSWER_FOUR = "RIDDLE_MODAL_ANSWER_FOUR"
export const CONFIRM_RIDDLE_EXIT = "CONFIRM_RIDDLE_EXIT"

export const REPORT_COMMENT_MODAL = "REPORT_COMMENT_MODAL"
export const SUCCESS_REPORT_MODAL = "SUCCESS_REPORT_MODAL"

export const SHARE_EMAIL_MODAL = "SHARE_EMAIL_MODAL"
export const CONFIRM_SHARE_EXIT = "CONFIRM_SHARE_EXIT"

export const CONFIRM_DELETE_MODAL = "CONFIRM_DELETE_MODAL"
