import styled from "styled-components"
import { colors } from "../../constants/colors"

type TextProps = {
    weight?: "bold" | 600 | "normal" | 700 | 500 | 400
    color?: keyof typeof colors
    inline?: boolean
}

export const StyledH1 = styled.h1<TextProps & { rtl?: boolean }>`
    margin: 0;
    font-style: normal;
    font-weight: ${({ weight = "normal" }) => weight};
    font-size: 40px;
    line-height: 44px;
    color: ${({ color = "black" }) => colors[color]};

    * {
        margin: 0 !important;
        font-style: normal;
        font-weight: ${({ weight = "normal" }) => weight} !important;
        font-size: 40px !important;
        line-height: 44px !important;
        color: ${({ color = "black" }) => colors[color]};
    }

    @media (max-width: 768px) {
        font-size: 30px;
        padding: 0 5px;

        * {
            font-size: 30px !important;
        }
    }
`

export const StyledH2 = styled.h2<TextProps & { rtl?: boolean }>`
    margin: 0;
    font-style: normal;
    font-weight: ${({ weight = "normal" }) => weight};
    font-size: 28px;
    line-height: 1.285;

    color: ${({ color = "black" }) => colors[color]};

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
`

export const StyledH3 = styled.h3<TextProps & { rtl?: boolean }>`
    margin: 0;
    font-style: normal;
    font-weight: ${({ weight = "normal" }) => weight};
    font-size: 20px;
    line-height: 27px;
    display: ${({ inline }) => !!inline && "inline"};
    color: ${({ color = "black" }) => colors[color]};

    * {
        margin: 0 !important;
        font-style: normal;
        font-weight: ${({ weight = "normal" }) => weight} !important;
        font-size: 20px !important;
        line-height: 27px !important;
        color: ${({ color = "black" }) => colors[color]} !important;
    }
`

export const StyledH4 = styled.h4<TextProps & { rtl?: boolean }>`
    margin: 0;
    font-style: normal;
    font-weight: ${({ weight = "normal" }) => weight};
    font-size: 18px;
    line-height: 25px;
    display: ${({ inline }) => !!inline && "inline"};

    color: ${({ color = "black" }) => colors[color]};
`

export const StyledBodyMain = styled.div<TextProps & { rtl?: boolean }>`
    margin: 0;
    font-style: normal;
    font-weight: ${({ weight = "400" }) => weight};
    font-size: 18px;
    line-height: 1.5;
    display: ${({ inline }) => !!inline && "inline"};
    margin-bottom: 10px;
    color: ${({ color = "black" }) => colors[color]};
`

export const StyledLink = styled.a<TextProps>`
    cursor: pointer;
    margin: 0;
    font-style: normal;
    font-weight: ${({ weight = "normal" }) => weight};
    font-size: 20px;
    line-height: 1.375;

    color: ${({ color = "blue" }) => colors[color]};

    @media (max-width: 768px) {
        font-size: 18px;
    }
`

export const StyledSubText = styled.span<TextProps & { rtl?: boolean }>`
    margin: 0;
    font-style: normal;
    font-weight: ${({ weight = "normal" }) => weight};
    font-size: 16px;
    line-height: 19px;
    display: ${({ inline }) => (inline ? "inline" : "block")};

    color: ${({ color = "black" }) => colors[color]};
    overflow-wrap: break-word;
`

export const StyledHighlightedText = styled.div<{ rtl?: boolean }>`
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    position: relative;
    display: inline-flex;

    &:after {
        background-color: ${colors.yellow};
        bottom: 0;
        display: inline-flex;
        content: "";
        height: 2px;
        position: absolute;
        width: 100%;
    }
`

export const StyledLinkSmall = styled(StyledLink)<TextProps>`
    font-size: 14px;
`

export const StyledAWithoutSpaces = styled.a`
    white-space: pre;
    cursor: pointer;
`

export const StyledLinkButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: ${colors.blue};
    text-align: left;
    font-size: 18px;

    p {
        margin: 0;
    }
`
