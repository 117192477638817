import { Hypothesis, DefinitionItem, EvidenceType } from "src/types"

export const roundNumber = (num: number) => {
    if (!num) return 0
    if (num >= 5) return Number(Math.round(num))
    if (num <= 0.1) return Number(num.toFixed(2))

    return Number(num.toFixed(1))
}

export const roundNumberHomePage = (num: number | null | undefined): string | number => {
    if (num === null || num === undefined) return "0"
    if (num < 0.0001) return "<0.0001"
    if (num < 0.001) return Number(num.toFixed(4))
    if (num < 0.01) return Number(num.toFixed(3))
    if (num < 0.1) return Number(num.toFixed(2))
    if (num < 5) return Number(num.toFixed(1))
    if (num < 95) return Number(num.toFixed(0))
    if (num < 99.9) return Number(num.toFixed(1))
    if (num < 99.99) return Number(num.toFixed(2))
    if (num < 99.999) return Number(num.toFixed(3))
    if (num < 99.9999) return Number(num.toFixed(4))

    return ">99.9999"
}

export const roundNumberClaim = (num: number | null): string | number => {
    if (num === null || num === 0) return "0"
    if (num > 0 && num < 0.0001) return "<0.0001"
    if (num >= 0.0001 && num < 0.001) return Number(num.toFixed(4))
    if (num >= 0.001 && num < 0.01) return Number(num.toFixed(3))
    if (num >= 0.01 && num < 0.1) return Number(num.toFixed(2))
    if (num >= 0.1 && num < 5) return Number(num.toFixed(1))
    if (num >= 5 && num < 99.9) return Number(num.toFixed(0))
    if (num >= 99.9 && num < 100) return ">99.9999"

    return "100"
}

export const removeSpaces = (str: string) => str.trim().split(" ").join("_")

export const removeSpaceSymbol = (str: string) => {
    if (str) {
        return str.replace(/&nbsp;/gi, " ") || ""
    }
    return ""
}

export const isBrowser = () => typeof window !== "undefined"

export const isEdit = () => {
    const { NEXT_PUBLIC_EDIT_SUBDOMAIN = "edit" } = process.env

    return isBrowser() && window.location.host.split(".")[0] === NEXT_PUBLIC_EDIT_SUBDOMAIN
}

export const checkEdit = (host?: string) => {
    const { NEXT_PUBLIC_EDIT_SUBDOMAIN = "edit" } = process.env

    return host && host.split(".")[0] === NEXT_PUBLIC_EDIT_SUBDOMAIN
}

export const isMobile = () => isBrowser() && navigator.userAgent.toLowerCase().includes("mobile")
export const isTablet = () =>
    isBrowser() &&
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        navigator.userAgent.toLowerCase()
    )

export const parseLinks = (text: string) =>
    text
        ? text.replace(
              /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim,
              (substring) => {
                  return `<a href=${substring} target="_blank">${substring}</a>`
              }
          )
        : ""

export const getUrl = () => (isBrowser() ? window.location.href : "")

export const generateNumber: (n: number) => string = (n) => {
    const add = 1
    let max = 12 - add

    if (n > max) {
        return generateNumber(max) + generateNumber(n - max)
    }

    max = Math.pow(10, n)
    const min = max / 10
    const number = Math.floor(Math.random() * (max - min + 1)) + min

    return "" + number
}

export const openLinkInNewTab = (idSelector: string) => {
    const content = document.getElementById(idSelector)
    if (content) {
        content.addEventListener("click", (event) => {
            const element = event.target as HTMLElement
            if (element.tagName === "A") {
                element.setAttribute("target", "_blank")
            }
        })

        return () =>
            content.removeEventListener("click", (event) => {
                const element = event.target as HTMLElement
                if (element.tagName === "A") {
                    element.setAttribute("target", "_blank")
                }
            })
    }
}

const addTargetBlankToLink = (str: string) => str.replace(/<a href/g, `<a target="_blank" href`)

export const addTargetBlankToStoryData = ({ ...story_data }) => {
    for (const e of [
        "background_info",
        "prior_explanation",
        "prior_summary",
        "new_evidence",
        "ignored_evidence",
    ]) {
        if (story_data[e]) story_data[e] = addTargetBlankToLink(story_data[e])
    }

    if (story_data?._evidence) {
        story_data._evidence.forEach((evidence: EvidenceType) => {
            for (const e of ["name", "background_info", "confidence_explanation"]) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (evidence[e]) evidence[e] = addTargetBlankToLink(evidence[e])
            }
        })
    }

    if (story_data?._definitions) {
        story_data._definitions.forEach((e: DefinitionItem) => {
            if (e?.description) e.description = addTargetBlankToLink(e.description)
        })
    }

    if (story_data?._hypotheses) {
        story_data._hypotheses.forEach((e: Hypothesis) => {
            if (e?.name) e.name = addTargetBlankToLink(e.name)
        })
    }
    return story_data
}
