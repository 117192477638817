import { MouseEvent } from "react"
import { StyledButton } from "./ButtonOutlined.styles"

type Props = {
    children: JSX.Element | string | Element
    onClick?: (event: MouseEvent) => void
    prefix?: boolean
    disabled?: boolean
    block?: boolean
    type?: "button" | "submit" | "reset"
}

const ButtonOutlined = ({
    children,
    prefix = false,
    onClick,
    disabled,
    block,
    type = "button",
}: Props) => {
    return (
        <StyledButton
            hasPrefix={prefix}
            onClick={onClick}
            disabled={disabled}
            block={block}
            type={type}
        >
            {children}
        </StyledButton>
    )
}

export default ButtonOutlined
