import styled from "styled-components"

type StylesProps = {
    block?: boolean
    disabled?: boolean
}

export const StyledButton = styled.button<StylesProps>`
    ${({ block }) => block && "width: 100%;"}
    border: none;
    background: ${({ disabled }) => (disabled ? "rgba(208, 173, 187, 0.5)" : "#13C384")};
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    // line-height: 22px;
    padding: 9px ${({ block }) => (block ? 0 : "20px")};
    text-align: center;
    color: #ffffff;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    position: relative;

    &:focus {
        outline: none;
    }

    &:hover {
        background: ${({ disabled }) => (disabled ? "rgba(208, 173, 187, 0.5)" : "#121212")};
    }

    &:active {
        outline: none;
        background: #a6114b;
    }

    a {
        color: #fff;
    }
`
