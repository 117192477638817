export const colors = {
    red: "#C2185B",
    blue: "#03A9F4",
    darkBlue: "#4e89e5",
    yellow: "#FBC02D",
    green: "#13C384",
    black: "#000000",
    colorIcon: "#191919",
    lineGrey: "#ECECEC",
    lineGreySecondary: "#515151",
    background: "#F5F5F5",
    backgroundSecondary: "#F1F1F1",
    white: "#FFFFFF",
    grey: "#6E6D79",
    lightGrey: "#D8D8D8",
    lightGreyThird: "#808080",
    orange: "#FF5722",
    violet: "#7C4DFF",
    navy: "#121212",
    lightGreySecondary: "#E2E2E2",
    darkBackground: "rgba(0, 0, 0, 0.6)",
    lightBackground: "#FBFBFB",
    overlayBackground: "rgba(0, 0, 0, 0.59)",
    profileSidebarBg: "#363636",
    headingBg: "#121212",
    subHeadingBg: "#d6d6d6",
}
