import { StyledButton } from "./ButtonPrimary.styles"

type Props = {
    children: JSX.Element | string | Element
    block?: boolean
    onClick?: () => void
    type?: "submit"
    disabled?: boolean
}

const ButtonPrimary = ({ children, block, onClick, type, disabled }: Props) => {
    return (
        <StyledButton block={block} type={type} disabled={disabled} onClick={onClick}>
            {children}
        </StyledButton>
    )
}

export default ButtonPrimary
