import React, { SyntheticEvent } from "react"

export type uiReducerState = {
    riddleAnswers: { [key: number]: number | null }
    hideHeader: boolean
    storylineActiveKey: number
    evidenceGroupActiveKeys: { [key: number]: boolean }
    action: (() => void) | null
    isEdit: boolean
}

export type modalReducerState = {
    modal: ModalPropsType[]
}

export type ModalPropsType = {
    id: string
    children: React.ReactNode
    title?: string
    subtitle?: string
    hideLogo?: boolean
    hideCross?: boolean
    index?: number
    size?: "small" | "default" | "auto"
    exitOnClickOutside?: boolean
    exitAction?: (e?: SyntheticEvent<Element, Event>) => void
}

export enum ModalActionType {
    ADD_MODAL = "@@ui/ADD_MODAL",
    REMOVE_MODAL = "@@ui/REMOVE_MODAL",
    REMOVE_ALL_MODAL = "@@ui/REMOVE_ALL_MODAL",
}

export enum RiddleAnswersActionType {
    SET_RIDDLE_ANSWERS = "@@ui/SET_RIDDLE_ANSWERS",
    RESET_RIDDLE_ANSWERS = "@@ui/RESET_RIDDLE_ANSWERS",
}

export enum SetHideHeaderActionType {
    SET_HIDE_HEADER = "@@ui/SET_HIDE_HEADER",
}

export enum SetStorylineActiveKeyActionType {
    SET_STORYLINE_ACTIVE_KEY = "@@ui/SET_STORYLINE_ACTIVE_KEY",
}

export enum SetEvidenceGroupActiveKeysActionType {
    SET_EVIDENCE_ACTIVE_KEYS = "@@ui/SET_EVIDENCE_ACTIVE_KEYS",
}

export enum SetActionActionType {
    SET_ACTION = "@@ui/SET_ACTION",
    REMOVE_ACTION = "@@ui/REMOVE_ACTION",
}
