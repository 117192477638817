import styled from "styled-components"
import { colors } from "../../../constants/colors"

type Props = {
    block?: boolean
}

export const StyledInput = styled.input<Props>`
    padding: 14px 20px;

    width: ${({ block }) => (block ? "100%" : "unset")};

    border: 1px solid ${colors.lightGrey};
    box-sizing: border-box;
    border-radius: 5px;

    &:focus {
        outline: none;
        border: 1px solid ${colors.grey};
    }
`
